<template>
  <div class="drag-drop-container" ref="self"
                                   @mousemove.capture="self_mousemoveCaptureHandler"
                                   @mouseout="self_mouseoutHandler"
                                   @mouseup="self_mouseupHandler">
    <slot />
    <div class="drag-contents" :style="dragContentsStyle" ref="dragContents" />
  </div>
</template>
<script>
  import './DragDropContainer.scss';

  export default {
    props: {
      groups: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        dragDOMNode: undefined,
        dragDOMNodeSize: undefined,
        dragOffset: undefined,
        mouse: undefined
      }
    },
    computed: {
      dragContentsStyle() {
        if (!this.mouse || !this.dragDOMNodeSize) {
          return { 'display': 'none' };
        }

        return {
          'left': (this.mouse.clientX + this.dragOffset.x) + 'px',
          'top': (this.mouse.clientY + this.dragOffset.y) + 'px',
          'width': this.dragDOMNodeSize.width + 'px',
          'height': this.dragDOMNodeSize.height + 'px'
        }
      }
    },
    mounted() {
      document.addEventListener('mouseleave', this.document_mouseleaveHandler);
    },
    methods: {
      document_mouseleaveHandler(event) {
        if (event.target === document) {
          this.$store.commit('dragAndDrop/stopDrag');
        }
      },
      self_mousemoveCaptureHandler(event) {
        if (this.$store.state.dragAndDrop.isDragging) {
          this.$store.commit('dragAndDrop/mouseMove', {
            clientX: event.clientX,
            clientY: event.clientY
          });
        }
      },
      self_mouseoutHandler(event) {
        if (event.target === this.$refs.self && this.$store.state.dragAndDrop.isDragging) {
          this.$store.commit('dragAndDrop/stopDrag');
        }
      },
      self_mouseupHandler(event) {
        if (this.$store.state.dragAndDrop.isDragging) {
          this.$store.commit('dragAndDrop/stopDrag');
        }
      }
    },
    watch: {
      '$store.state.dragAndDrop.dragDOMNode': function (dragDOMNode) {
        if (dragDOMNode && this.$refs.dragContents) {
          while (this.$refs.dragContents.firstChild) {
            this.$refs.dragContents.removeChild(this.$refs.dragContents.firstChild);
          }

          this.$refs.dragContents.appendChild(dragDOMNode);
        }
        this.dragDOMNode = dragDOMNode;
      },
      '$store.state.dragAndDrop.dragOffset': function (dragOffset) {
        this.dragOffset = dragOffset;
      },
      '$store.state.dragAndDrop.dragDOMNodeSize': function (dragDOMNodeSize) {
        this.dragDOMNodeSize = dragDOMNodeSize;
      },
      '$store.state.dragAndDrop.mouse.cur': function (mouse) {
        this.mouse = mouse;
      }
    }
  }
</script>
