export default class TimeAdjustment {
  //---------------------------------------------------------------------------
  // Constructor
  //---------------------------------------------------------------------------
  constructor(milliseconds) {
    this.milliseconds = milliseconds;
  }

  //---------------------------------------------------------------------------
  // Serialization
  //---------------------------------------------------------------------------
  static fromJSON(json) {
    return new TimeAdjustment(json.milliseconds);
  }

  toJSON() {
    return {
      milliseconds: this.milliseconds
    }
  }
}