import TimeAdjustment from './TimeAdjustment';

export default class TimeAdjustmentCollection {
  //---------------------------------------------------------------------------
  // Constructor
  //---------------------------------------------------------------------------
  constructor(adjustments = []) {
    this.adjustments = adjustments;
  }

  //---------------------------------------------------------------------------
  // Properties
  //---------------------------------------------------------------------------
  get time() {
    return this.adjustments.reduce((totalMs, adjustment) => totalMs + adjustment.milliseconds, 0);
  }

  //---------------------------------------------------------------------------
  // Methods
  //---------------------------------------------------------------------------
  add(milliseconds) {
    this.adjustments.push(new TimeAdjustment(milliseconds));
  }

  reset() {
    this.adjustments = [];
  }

  //---------------------------------------------------------------------------
  // Serialization
  //---------------------------------------------------------------------------
  static fromJSON(json) {
    return new TimeAdjustmentCollection(json.adjustments.map(TimeAdjustment.fromJSON));
  }

  toJSON() {
    return {
      adjustments: this.adjustments.map(a => a.toJSON())
    }
  }
}