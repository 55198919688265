export default class Interval {
  //---------------------------------------------------------------------------
  // Constructor
  //---------------------------------------------------------------------------
  constructor(startDate, endDate) {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  //---------------------------------------------------------------------------
  // Properties
  //---------------------------------------------------------------------------
  get isActive() {
    return this.startDate && !this.endDate;
  }

  get time() {
    if (this.startDate && this.endDate) {
      return this.endDate.getTime() - this.startDate.getTime();
    }

    return (new Date()).getTime() - this.startDate.getTime();
  }

  //---------------------------------------------------------------------------
  // Methods
  //---------------------------------------------------------------------------
  start() {
    this.startDate = new Date();

    return this;
  }

  stop() {
    this.endDate = new Date();

    return this;
  }

  clone() {
    return new Interval(this.startDate, this.endDate);
  }

  isWithin(startDate, endDate) {
    if (!this.endDate) {
      if (new Date().getTime() - endDate.getTime() < 60000) {
        return true;
      }
      return this.startDate.getTime() >= startDate.getTime();
    }

    const isStartDateWithin = this.startDate.getTime() >= startDate.getTime() && this.startDate.getTime() <= endDate.getTime();
    const isEndDateWithin = this.endDate.getTime() >= startDate.getTime() && this.endDate.getTime() <= endDate.getTime();

    return isStartDateWithin || isEndDateWithin;
  }
  //---------------------------------------------------------------------------
  // Serialization
  //---------------------------------------------------------------------------
  static fromJSON(json) {
    return new Interval(json.startTime ? new Date(json.startTime) : undefined, json.endTime ? new Date(json.endTime) : undefined);
  }

  toJSON() {
    return {
      startTime: this.startDate.getTime(),
      endTime: this.endDate ? this.endDate.getTime() : undefined
    }
  }
}
