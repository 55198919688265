class Tag {
  //---------------------------------------------------------------------------
  // Constructor
  //---------------------------------------------------------------------------
  constructor(name) {
    this.name = name;
  }

  //---------------------------------------------------------------------------
  // Serialization
  //---------------------------------------------------------------------------
  static fromJSON(json) {
    return new Tag(json.name);
  }

  toJSON() {
    return {
      name: this.name
    }
  }
}

export default Tag;