export const dragAndDropInitialState = {
  mouse: {
    down: undefined,
    cur: undefined
  },
  dragOffset: undefined,
  dragGroup: undefined,
  dragData: undefined,
  dragDOMNode: undefined,
  dragDOMNodeSize: undefined,
  isDragging: false
};

export const state = () => dragAndDropInitialState;

export const mutations = {
  attemptDragData(state, { dragData, mouse }) {
    state.mouse.down = state.mouse.cur = mouse;
    state.dragData = dragData;
    state.isDragging = true;
  },
  acceptDrag(state, { dragDOMNode, dragDOMNodeSize, dragOffset }) {
    state.dragDOMNode = dragDOMNode;
    state.dragDOMNodeSize = dragDOMNodeSize;
    state.dragOffset = dragOffset;
  },
  stopDrag(state) {
    state.mouse.down = state.mouse.cur = undefined;
    state.isDragging = false;
    state.dragData = undefined;
    state.dragDOMNode = undefined;
    state.dragDOMNodeSize = undefined;
    state.dragOffset = undefined;
  },
  mouseMove(state, mouse) {
    state.mouse.cur = mouse;
  }
}
