<template>
  <drag-drop-container group="tasks">
    <div :class="scrolledClass">
      <Header />
      <div class="body" :style="bodyStyle">
        <nuxt />
      </div>
      <Footer />
    </div>
  </drag-drop-container>
</template>

<script>
  import Header from "~/layouts/Header";
  import Footer from "~/layouts/Footer";
  import DragDropContainer from '~/components/dragAndDrop/DragDropContainer';
  import classnames from 'classnames';

  import '../styles/base.scss';

  export default {
    components: {
      Header,
      Footer,
      DragDropContainer
    },
    data() {
      return {
        scrolled: false
      }
    },
    mounted() {
      window.onscroll = () => {
        this.scrolled = window.scrollY > 40 && document.body.scrollHeight - 30 > window.innerHeight;
      };
    },
    computed: {
      bodyStyle() {
        if (this.scrolled) {
          const header = document.getElementById('header');
          const rect = header.getBoundingClientRect();
          return {
            marginTop: rect.height + 'px'
          };
        }

        return {
          marginTop: 0
        }
      },
      scrolledClass() {
        return classnames({
          scrolled: this.scrolled
        });
      }
    }
  };
</script>
