<template>
  <div class="workspace-summary">
    <div class="total-time">{{timeDisplayed}}</div>
    <div class="current-task" v-if="taskNodeBreadcrumbs.length">
      <i class="fas fa-user-clock" />
      <div class="breadcrumbs">
        <div class="breadcrumb" v-for="taskNode in taskNodeBreadcrumbs" v-bind:key="taskNode.id">
          {{taskNode.name}}
        </div>
      </div>
    </div>
    <div class="current-task" v-else>
      <i class="fas fa-glass-cheers" />You are not working right now.
    </div>
    <div class="controls" v-if="workspace">
      <button @click.prevent="stopAll_clickHandler" v-if="taskIsRunning">
        <i class="far fa-stop-circle" />
      </button>
    </div>
  </div>
</template>

<script>
  import './WorkspaceSummary.scss';
  import { globalState } from '~/store/global';
  import { msToTime } from '~/utils/FormatUtil';

  export default {
    name: 'workspace-summary',
    props: {
      classes: String
    },
    data() {
      return {
        workspace: undefined,
        time: 0,
        taskIsRunning: false
      }
    },
    computed: {
      timeDisplayed() {
        if (!this.workspace) {
          return 'Loading...';
        }

        return msToTime(this.time);
      },
      taskNodeBreadcrumbs() {
        if (!this.workspace) return [];

        const { activeTask } = this.workspace.curTaskBoard.taskNodeRoot;

        return activeTask ? activeTask.breadcrumbs : [];
      }
    },
    methods: {
      stopAll_clickHandler() {
        globalState.workspace.curTaskBoard.taskNodeRoot.stopAll();
      }
    },
    watch: {
      '$store.state.global.update': function(cur, prev) {
        this.workspace = globalState.workspace;
        this.time = this.workspace ? this.workspace.curTaskBoard.time : 0;
        this.taskIsRunning = globalState.workspace ? globalState.workspace.curTaskBoard.taskNodeRoot.isAnyActive : false;
        this.$forceUpdate();
      }
    }
  };
</script>
