<template>
  <div class="workspace-controls">
    <h4>Workspace Settings</h4>
    <div class="controls">
      <input type="checkbox" :checked="showArchived" @change="showArchived_changeHandler">Show Archived
      <input type="checkbox" :checked="showCompact" @change="showCompact_changeHandler">Show Compact
      <input type="text" @blur="backgroundUrl_blurHandler" placeholder="Background Url" ref="backgroundUrl" :value="workspaceBackgroundUrl" />
      <alert-button alertText="Resetting the board erases everything. Are you sure?" 
                    confirmText="Yep" 
                    buttonClass="inverted"
                    @action="resetBoard_actionHandler"
                    cancelText="Nope">
        Reset Board
      </alert-button>
    </div>
  </div>
</template>

<script>
  import './WorkspaceControls.scss';
  import AlertButton from '~/components/AlertButton';
  import { globalState } from '~/store/global';

  export default {
    name: 'workspace-controls',
    props: {
      classes: String
    },
    components: {
      AlertButton
    },
    data() {
      return {
        workspace: undefined,
        workspaceName: '',
        showArchived: false,
        showCompact: false,
        workspaceBackgroundUrl: ''
      }
    },
    mounted() {
      setInterval(() => {
        this.workspace = globalState.workspace;
        this.workspaceName = this.workspace ? this.workspace.name : '';
        this.workspaceBackgroundUrl = this.workspace ? this.workspace.ui.backgroundUrl : '';
        this.showArchived = this.workspace ? this.workspace.ui.showArchived : false;
        this.showCompact = this.workspace ? this.workspace.ui.showCompact : false;
      }, 100);
    },
    methods: {
      showArchived_changeHandler(event) {
        this.workspace.ui.showArchived = event.target.checked;
      },
      showCompact_changeHandler(event) {
        this.workspace.ui.showCompact = event.target.checked;
      },
      resetBoard_actionHandler() {
        this.workspace.curTaskBoard.reset();
      },
      backgroundUrl_blurHandler() {
        this.workspace.ui.backgroundUrl = this.$refs.backgroundUrl.value;
      }
    }
  };
</script>
