<template>
  <div class="footer">
    <div class="copyright">Copyright (&copy;) Josh and Andreas, 2021</div>
  </div>
</template>

<script>
  import './Footer.scss';
  export default {
    components: {
    }
  };
</script>
