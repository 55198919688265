import Interval from './Interval';

export default class IntervalCollection {
  //---------------------------------------------------------------------------
  // Constructor
  //---------------------------------------------------------------------------
  constructor(intervals = []) {
    this.intervals = intervals;
  }

  //---------------------------------------------------------------------------
  // Properties
  //---------------------------------------------------------------------------
  get isActive() {
    return this.latestInterval && this.latestInterval.isActive;
  }

  get latestInterval() {
    return this.intervals.length ? this.intervals[this.intervals.length - 1] : undefined;
  }

  get firstInterval() {
    return this.intervals[0];
  }

  get lastInterval() {
    return this.intervals[this.intervals.length - 1];
  }

  get minDate() {
     return this.firstInterval ? this.firstInterval.startDate : undefined;
  }

  get maxDate() {
    return this.lastInterval ? this.lastInterval.endDate || new Date() : undefined;
  }

  get hasIntervals() {
    return this.intervals.length > 0;
  }

  //---------------------------------------------------------------------------
  // Methods
  //---------------------------------------------------------------------------
  deleteInterval(interval) {
    const ix = this.intervals.indexOf(interval);
    if (ix > -1) {
      this.intervals.splice(ix, 1);
    }
  }
  start() {
    if (this.isActive) {
      return; // We are already running
    }

    const newInterval = new Interval();
    newInterval.start();

    this.intervals.push(newInterval);
  }

  stop() {
    if (!this.isActive) {
      return; // We are not running, nothing to stop
    }

    this.latestInterval.stop();
  }

  get time() {
    return this.intervals.reduce((prevMs, curInterval) => curInterval.time + prevMs, 0);
  }

  //---------------------------------------------------------------------------
  // Serialization
  //---------------------------------------------------------------------------
  clone() {
    return new IntervalCollection({
      intervals: this.intervals.map(i => i.clone())
    });
  }

  static fromJSON(json) {
    return new IntervalCollection(json.intervals.map(intervalJSON => Interval.fromJSON(intervalJSON)));
  }

  toJSON() {
    return {
      intervals: this.intervals.map(interval => interval.toJSON())
    }
  }
}
