import { randomId} from '~/utils/IdUtil';

import TaskIntervalCollection from '../intervals/IntervalCollection';
import TimeAdjustmentCollection from '../time/TimeAdjustmentCollection';

import IntervalCollection from '../intervals/IntervalCollection';
import Tag from '../tags/Tag';

const default_ui = {
  expanded: true
};

export default class TaskNode {
  //---------------------------------------------------------------------------
  // Constructor
  //---------------------------------------------------------------------------
  constructor(name, parent, tasks = [], id = undefined, intervalCollection = undefined, taskBoard=undefined) {
    this.id = id || randomId();
    this.name = name;
    this.intervalCollection = intervalCollection || new TaskIntervalCollection();

    this.tags = [];
    this.archived = false;

    this.description = '';
    this.links = [];

    this._taskBoard = taskBoard;

    this.timeAdjustments = new TimeAdjustmentCollection();

    this.ui = {...default_ui};

    this.tasks = [];
    this.parent = parent;

    tasks ? tasks.forEach(t => this.addTask(t)) : undefined;
  }

  //---------------------------------------------------------------------------
  // Properties
  //---------------------------------------------------------------------------
  get root() {
    if (this.isRoot) {
      return this;
    }

    return this.parent.root;
  }

  get taskBoard() {
    if (this._taskBoard) return this._taskBoard;
    if (this.parent) return this.parent.taskBoard;

    return undefined;
  }

  get isParent() {
    return this.tasks.length > 0;
  }

  get isEditing() {
    return this.taskBoard.editingTaskNode === this;
  }

  get isSelected() {
    return this.taskBoard.selTaskNode === this;
  }

  get isRoot() {
    return !this.parent;
  }

  get isNew() {
    return !this.name && this.time === 0 && this.adjustmentTime === 0;
  }

  get isLeaf() {
    return this.tasks.length === 0;
  }

  get isActive() {
    return this.intervalCollection.isActive;
  }

  get isAnyActive() {
    return this.isActive || !!this.tasks.find(t => t.isAnyActive);
  }

  get time() {
    let childTime = 0;

    this.tasks.forEach(task => {
      childTime += task.time;
    });

    return this.selfTime + childTime;
  }

  get selfTime() {
    return this.intervalCollection.time + this.timeAdjustments.time;
  }

  get childTime() {
    let childTime = 0;

    this.tasks.forEach(task => {
      childTime += task.time;
    });

    return childTime;
  }

  get adjustmentTime() {
    return this.timeAdjustments.time;
  }

  get hasChildren() {
    return this.tasks.length > 0;
  }

  get hasRenderedChildren() {
    return this.renderedTasks.length > 0;
  }

  get activeTask() {
    return this.find(task => task.isActive);
  }

  get breadcrumbs() {
    if (this.isRoot) return [];

    return [...this.parent.breadcrumbs, this];
  }

  get isSearchResult() {
    return this.taskBoard.searchFoundTasks ? this.taskBoard.searchFoundTasks.indexOf(this) !== -1 : false;
  }

  get renderedTasks() {
    if (this.taskBoard.workspace.ui.showArchived) {
      return this.tasks;
    }

    return this.tasks.filter(t => !t.archived);
  }

  get filteredTasks() {
    return this.tasks;
  }

  get doneCount() {
    let count = 0;
    this.forEach(t => {
      if (t.is('done')) count++;
    }, false);
    return count;
  }

  get totalCount() {
    let count = 0;
    this.forEach(t => count++, false);
    return count;
  }

  get archivedCount() {
    let count = 0;
    this.forEach(t => t.archived ? count++ : 0, false);
    return count;
  }

  getTaskById(id) {
    return this.find(tn => tn.id === id);
  }

  //---------------------------------------------------------------------------
  // Methods
  //---------------------------------------------------------------------------
  indexOf(taskNode) {
    return this.tasks.indexOf(taskNode);
  }

  isTaskDescendent(taskNode) {
    let isDescendent = false;

    this.forEach(t => {
      if (t === taskNode) {
        isDescendent = true;
      }
    }, false);

    return isDescendent;
  }
  collapse() {
    this.ui.expanded = false;
  }

  is(tagName) {
    return this.tags.find(t => t.name === tagName);
  }

  hasAtLeastOneOf(taskNodes) {
    let found = false;

    this.forEach(taskNode => {
      if (taskNodes.indexOf(taskNode) !== -1) found = true;
    });

    return found;
  }

  /**
   * @param {} tag  Instance of Tag
   * @returns
   */
  addTag(tag) {
    if (this.is(tag)) return;

    this.tags.push(tag);

    return this;
  }

  removeTag(tag) {
    if (!this.is(tag.name)) return;

    this.tags.splice(this.tags.indexOf(tag), 1);

    return this;
  }

  find(callback) {
    if (callback(this)) {
      return this;
    }

    let foundSubTask = undefined;

    this.tasks.forEach(task => {
      foundSubTask = foundSubTask || task.find(callback);
    });

    return foundSubTask;
  }

  forEach(callback, includeSelf = true) {
    if (includeSelf) {
      callback(this);
    }

    this.tasks.forEach(task => task.forEach(callback));
  }

  start() {
    this.intervalCollection.start();
  }

  stop() {
    this.intervalCollection.stop();
  }

  stopAll() {
    this.stop();

    this.tasks.forEach(task => task.stopAll());
  }

  toggle() {
    if (this.isActive) {
      this.stop();
    } else {
      this.start();
    }
  }

  addTask(task, ix = -1) {
    if (!task) {
      task = new TaskNode('', this);
    }

    task.parent = this;

    if (ix === -1) {
      this.tasks.push(task);
    } else {
      this.tasks.splice(ix, 0, task);
    }

    return task;
  }

  addTaskAfter(newTask, prevTask) {
    newTask.parent = this;
    this.tasks.splice(this.tasks.indexOf(prevTask) + 1, 0, newTask);
  }

  addTaskBefore(newTask, prevTask) {
    newTask.parent = this;
    this.tasks.splice(this.tasks.indexOf(prevTask), 0, newTask);
  }

  deleteTask(task) {
    this.tasks.splice(this.tasks.indexOf(task), 1);
  }

  archive() {
    // Just delete it completely if it is empty
    if (this.isNew) {
      this.parent.deleteTask(this);
      return;
    }

    this.stop();
    this.archived = true;

    this.forEach(task => task.archive(), false);
  }

  restore() {
    this.archived = false;
  }

  clone(parent=undefined) {
    const newTaskNode = new TaskNode(this.name, parent, undefined, this.intervalCollection.clone());

    this.tasks.forEach(t => {
      newTaskNode.addTask(t.clone(newTaskNode));
    });

    return newTaskNode;
  }

  resetAdjustments() {
    this.timeAdjustments.reset();
  }

  adjust(milliseconds) {
    if (this.selfTime + milliseconds <= 0) {
      milliseconds = -this.selfTime;
    }

    this.timeAdjustments.add(milliseconds);
  }

  getDeepestVerticalNode(showArchived) {
    if (!this.tasks.length || !this.ui.expanded) return this;

    if (showArchived) {
      return this.tasks[this.tasks.length - 1].getDeepestVerticalNode(showArchived);
    } else {
      // NOT showing archived
      const lastUnarchivedSubTask = this.tasks.concat().reverse().find(t => !t.archived);

      if (lastUnarchivedSubTask) {
        return lastUnarchivedSubTask.getDeepestVerticalNode(showArchived);
      }
    }

    return this;
  }

  getNextSibling(showArchived) {
    if (!this.parent) {
      return undefined;
    }
    let nextIx = this.parent.tasks.indexOf(this) + 1;

    // Next Sibling?
    while (nextIx < this.parent.tasks.length) {
      const nextSibling = this.parent.tasks[nextIx];

      if (nextSibling.archived && !showArchived) {
        nextIx++;
      } else {
        return nextSibling;
      }
    }

    return this.parent ? this.parent.getNextSibling(showArchived) : undefined;
  }

  getPrevVerticalNode(showArchived = false) {
    if (this.parent) {
      let prevIx = this.parent.tasks.indexOf(this) - 1;

      // Previous Sibling?
      while (prevIx >= 0) {
        const prevTask = this.parent.tasks[prevIx];

        if (!prevTask.archived || showArchived) {
          return prevTask.getDeepestVerticalNode(showArchived);
        } else {
          prevIx--;
        }
      }

      return this.parent;
    }
  }

  // ARROW DOWN
  getNextVerticalNode(showArchived = false) {
    // Our children
    if (this.tasks.length && this.ui.expanded) {
      if (!showArchived) {
        const firstNonArchivedChild = this.tasks.find(t => !t.archived);

        if (firstNonArchivedChild) {
          return firstNonArchivedChild;
        }
      } else {
        return this.tasks[0];
      }
    }

    // Our next sibling
    return this.getNextSibling(showArchived);
  }

  expand(expandParent = true) {
    this.ui.expanded = true;

    if (expandParent && this.parent) {
      this.parent.expand();
    }
  }

  expandParents() {
    this.parent.expand();
  }

  //---------------------------------------------------------------------------
  // Serialization
  //---------------------------------------------------------------------------
  static fromJSON(json) {
    const newTaskNode = new TaskNode(json.name, undefined, [], json.id, IntervalCollection.fromJSON(json.intervalCollection));

    newTaskNode.tags = json.tags ? json.tags.map(tagJSON => Tag.fromJSON(tagJSON)) : [];
    newTaskNode.ui = json.ui || default_ui;
    newTaskNode.timeAdjustments = json.timeAdjustments ? TimeAdjustmentCollection.fromJSON(json.timeAdjustments) : newTaskNode.timeAdjustments;
    newTaskNode.archived = json.archived;
    newTaskNode.description = json.description || '';
    newTaskNode.links = json.links || [];

    json.tasks.map(taskJSON => {
      const newChildTaskNode = TaskNode.fromJSON(taskJSON);
      newTaskNode.addTask(newChildTaskNode);
    });

    return newTaskNode;
  }

  toJSON() {
    return {
      id: this.id,
      ui: this.ui,
      timeAdjustments: this.timeAdjustments.toJSON(),
      name: this.name,
      links: this.links,
      description: this.description,
      archived: this.archived,
      tags: this.tags.map(t => t.toJSON()),
      intervalCollection: this.intervalCollection.toJSON(),
      tasks: this.tasks.map(task => task.toJSON())
    }
  }
}
