export const globalState = {
  workspace: undefined,
  user: undefined
};

export const state = () => ({
  globalSettings: undefined,
  update: 0,
  scrollToTask: undefined
});

export const mutations = {
  setGlobalSettings(state, globalSettings) {
    state.globalSettings = globalSettings;
  },
  update(state) {
    state.update = state.update + 1;
  }
}