<template>
  <div class="search-container" v-if="taskNodeRoot && taskNodeRoot.tasks.length">
    <input placeholder="Search Tasks..." :value="searchText" @input="search_inputHandler" />
    <button @click="charts_clickHandler" v-if="taskNodeRoot && taskNodeRoot.time > 0"><i class="fas fa-chart-pie" /></button>
    <button @click.prevent="addTask_clickHandler" class="highlight"><i class="fas fa-plus" /> Task</button>  
  </div>
</template>
<script>
import TaskNodeModel from '~/model/workspace/tasks/TaskNode';
import { globalState } from '~/store/global';

import './WorkspaceSearch.scss';

export default {
  data() {
    return {
      searchText: '',
      taskNodeRoot: undefined
    }
  },
  methods: {
    addTask_clickHandler(event) {
      globalState.workspace.curTaskBoard.search('');

      if (!globalState.workspace.curTaskBoard.editingTaskNode) {
        event.stopPropagation();
        const newTask = new TaskNodeModel();
        globalState.workspace.curTaskBoard.taskNodeRoot.addTask(newTask);
        globalState.workspace.curTaskBoard.selTaskNode = globalState.workspace.curTaskBoard.editingTaskNode = newTask;
      }
    },
    charts_clickHandler(event) {
      globalState.workspace.ui.showChartsModal = true;
      this.$forceUpdate();
    },
    search_inputHandler(event) {
      globalState.workspace.curTaskBoard.search(event.target.value);
    }
  },
  watch: {
    '$store.state.global.update': function(cur, prev) {
      if (globalState.workspace) {
        this.searchText = globalState.workspace.curTaskBoard.ui.searchText;
        this.taskNodeRoot = globalState.workspace.curTaskBoard.taskNodeRoot;
      }
      this.$forceUpdate();
    }
  }
};
</script>
