<template>
  <div :class="headerClasses()" id="header">
    <div class="header-bar flex column">
      <div class="flex row space-between">
        <nav class="uk-navbar-container" uk-navbar>
          <div class="uk-navbar-left">
            <img class="logo" src="/logo.svg" />
          </div>
        </nav>
        <workspace-summary />
        <button class="flat" @click="showWorkspaceControls_clickHandler">
          <i class="fas fa-cog" />
          <i class="fas fa-chevron-down" v-if="!showWorkspaceControls" />
          <i class="fas fa-chevron-up" v-if="showWorkspaceControls" />
        </button>
      </div>
      <workspace-search />
    </div>

    <div :class="workspaceControlsDropdownClasses">
      <workspace-controls />
    </div>
  </div>
</template>

<script>
  import './Header.scss';
  import classnames from 'classnames';

  import WorkspaceSummary from '~/components/WorkspaceSummary';
  import WorkspaceSearch from '~/components/WorkspaceSearch';
  import WorkspaceControls from '~/components/WorkspaceControls';

  import { globalState} from '~/store/global';

  export default {
    components: {
      WorkspaceSummary,
      WorkspaceControls,
      WorkspaceSearch
    },
    data() {
      return {
        showWorkspaceControls: false
      }
    },
    computed: {
      workspaceControlsDropdownClasses() {
        if (process.client) {
          return classnames({
            'workspace-controls-dropdown': true,
            'expand': this.showWorkspaceControls
          });
        } else {
          return 'workspace-controls-dropdown'
        }
      }
    },
    mounted() {
      setInterval(() => {
        this.showWorkspaceControls = globalState.workspace ? globalState.workspace.ui.workspaceControlsOpen : false;
      }, 100);
    },
    methods: {
      headerClasses() {
        return 'header';
      },
      showWorkspaceControls_clickHandler() {
        globalState.workspace.ui.workspaceControlsOpen = !globalState.workspace.ui.workspaceControlsOpen;
      }
    }
  };
</script>
