export function msToTime(millis) {
  if (!millis) {
    return '';
  }

  let s = Math.abs(millis);
  let ms = s % 1000;
  s = (s - ms) / 1000;
  let secs = s % 60;
  s = (s - secs) / 60;
  let mins = s % 60;
  let hrs = (s - mins) / 60;

  let zPrefix = (t) => t < 10 ? '0' + t : t;

  const sign = (millis < 0 ? '-' : '');
  const hours = (hrs ? hrs + ':' : '')

  return sign + hours + (hrs ? zPrefix(mins) : mins) + ':' + zPrefix(secs);
}

export function msToAdjustmentTime(millis) {
  if (!millis) {
    return '';
  }

  return msToTime(millis);
}