<template>
  <div class="alert-button">
    <button :class="buttonClass" @click.prevent="button_clickHandler"><slot/></button>
    <div :class="alertContainerClass">
      <div class="alert-text">{{alertText}}</div>
      <div class="alert-button-container">
        <button class="highlight" @click.prevent="confirm_clickHandler">{{confirmText}}</button>
        <button @click.prevent="cancel_clickHandler">{{cancelText}}</button>
      </div>
    </div>
  </div>
</template>
<script>
import './AlertButton.scss';

export default {
  props: ['alertText', 'confirmText', 'cancelText', 'buttonClass'],
  data() {
    return {
      confirming: false
    }
  },
  methods: {
    button_clickHandler() {
      this.confirming = true;
    },
    confirm_clickHandler() {
      this.$emit('action');
      this.confirming = false;
    },
    cancel_clickHandler() {
      this.confirming = false;
    }
  },
  computed: {
    alertContainerClass() {
      if (this.confirming) {
        return 'alert-container show';
      }
      return 'alert-container';
    }
  }
}
</script>
